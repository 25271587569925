import React, {useState} from 'react';
import {useStripe, useElements, ExpressCheckoutElement} from '@stripe/react-stripe-js';
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getData, postData } from "../service/SmileService";

const expressCheckoutOptions = {
  buttonHeight: 50,
  buttonTheme: {
    applePay: 'black',
    googlePay: 'white'
  },
  paymentMethods: {
    applePay: 'always',
    googlePay: 'always'
  },
  layout:{
    maxColumns: 3,
    maxRows: 2,
    overflow: 'auto'
  },
  buttonType: {
    applePay: 'plain',
    googlePay: 'plain'
  }
};

const ExpressCheckout = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Initialize useNavigate hook
  const childData = useSelector((state) => state.child);

  const splitFullName = (fullName) => {
    // Split the full name by spaces
    const nameParts = fullName.split(' ');
  
    // Check if there's more than one part
    if (nameParts.length > 1) {
      // Combine all parts except the last one for the first name
      const firstName = nameParts.slice(0, -1).join(' ');
      // Take the last part as the last name
      const lastName = nameParts[nameParts.length - 1];
  
      return { firstName, lastName };
    } else {
      // If there's only one part, return it as the first name and an empty last name
      return { firstName: fullName, lastName: '' };
    }
  };

  const onClick = ({resolve}) => {
    const options = {
      emailRequired: true,
      phoneNumberRequired: true
    };
    resolve(options);
  };

  const onConfirm = async (event) => {
    if (!stripe) {
      // Stripe.js hasn't loaded yet.
      // Make sure to disable form submission until Stripe.js has loaded.
      console.log("Stripe loading......");
      return;
    }
    
    console.log("Getting payments -- through express checkout" + JSON.stringify(event));
    
    const {error: submitError} = await elements.submit();
    if (submitError) {
        console.log("Some error loading stripe..." + submitError.message);
      setError(submitError.message);
      return;
    }

    try {
        // Call your custom endpoint to create a Payment Intent
        // Create a dummy payload
        const { firstName, lastName } = splitFullName(event.billingDetails.name);
        const payload = {
          firstName: firstName,
          lastName: lastName,
          email: event.billingDetails.email,
          lang: "en",
          address: {postalcode: event.billingDetails.address.postal_code, country: event.billingDetails.address.country},
          //
          subscriptionProducts: [
            {
              productId: process.env.REACT_APP_STRIPE_PRODUCT_KEY,
              frequency: process.env.REACT_APP_DONATION_FREQUENCY,
              paymentIntervalMultiplier: 1,
              amount: process.env.REACT_APP_DONATION_AMOUNT,
              quantity: 1,
              descode: process.env.REACT_APP_CAMPAIGN_DESC_CODE,
              pledgetype: process.env.REACT_APP_PRODUCT_PLEDGE_TYPE,
              projectid: childData.projectId,
              childnumber: childData.childNumber,
              country: childData.country,
              firstname: childData.firstName + " " + childData.lastName,
              gender: childData.genderCode,
              childImageUrl: childData.imageLatestUrl,
              birthDay: childData.birthday,
              age: childData.age
            },
          ],
          packageid: process.env.REACT_APP_CAMPAIGN_PACKAGE_ID,
          source: process.env.REACT_APP_DONATION_SOURCE
        };
  
        const response = await getData("", "/api/generate-token");

        let accessToken = response.access_token;
        console.log("access token: ", accessToken);

        let requestData = {
          url: process.env.REACT_APP_STRIPE_TRANSACTION_API_URL + 'create-payments', // The URL you want to hit
          data: payload,
          accessToken: accessToken
        };

        const data = await postData('','/api/create-transaction',requestData);
      
        console.log("data received from transaction service call:", data);

        const clientSecret = data[0].clientSecret;
        // Confirm the card payment
        await stripe.confirmPayment({
          // `elements` instance used to create the Express Checkout Element
          elements,
          // `clientSecret` from the created PaymentIntent
          clientSecret,
          confirmParams: {
              return_url: window.location.origin + '/thankyou',
          },
          redirect: 'if_required'
        })
        .then(async function (result) {
              if (result.error) {
                // Inform the customer that there was an error.
                console.log(result.error.message);
                setError(result.error.message);
              } else {
                // Handle next step based on PaymentIntent's status.
                let apiResponse = await postData(process.env.REACT_APP_SPONSORSHIP_API_URL,
                  "/child/lock/sponsored/" +
                    childData.projectId +
                    "-" +
                    childData.childNumber
                );
                console.log("API Response from lock child service: " + apiResponse);
                console.log("PaymentIntent ID: " + result.paymentIntent.id);
                console.log("PaymentIntent status: " + result.paymentIntent.status);
                
                navigate("/thankyou");
              }
        }).catch(err => {
          console.log(err.message);
          setError(err.message);
        });
    } 
    catch (err) {
      if(err.response && err.response.data.errors){
        console.log(JSON.stringify(err.response.data.errors));
        setError(err.response ? JSON.stringify(err.response.data.errors) :err.message);
      }
      else{
        console.log(err.message);
        setError(err.message);
      }
    }
  };

  return (
    
        <div id="checkout-page">
            <ExpressCheckoutElement onConfirm={onConfirm} onClick={onClick} options={expressCheckoutOptions}/>
            {error && <div>{error}</div>}
        </div>
  );
};

export default ExpressCheckout;